export interface ResponseWithDataError {
  data?: {message: string};
  error?: {message: string};
}

export const fetchWithAuth = async (
  input: RequestInfo,
  init?: RequestInit
): Promise<Response | undefined> => {
  let authElement = document.querySelector('meta[name="csrf-token"]');

  // hack for tests - could not get this working in the setup file
  if (process.env.NODE_ENV === 'test') {
    authElement = document.createElement('div');
    authElement.setAttribute('content', 'TEST');
  }

  if (!authElement) {
    return null;
  }
  const authToken = authElement.getAttribute('content');

  if (!init) {
    return fetch(input, {
      headers: {'X-CSRF-Token': authToken!, 'X-DEALMAKER-API': 'true'},
    });
  }

  return fetch(input, {
    ...init!,
    headers: {
      ...init!.headers,
      'X-CSRF-Token': authToken!,
      'X-DEALMAKER-API': 'true',
    },
  });
};

export const baseURL = window.location.href.split('?')[0];
export const originURL = window.location.origin;

export const redirectToLogin = (): void => {
  window.location.href = `${originURL}/users/sign_in`;
};
